<template>
  <v-select
    :rules="rules"
    :prepend-icon="icon ? icons.mdiGroup : null"
    :disabled="disabled"
    v-model="datoselect"
    :items="datos"
    label="Moneda"
    item-value="id"
    item-text="nombre"
    :dense="outlined"
    :outlined="outlined"
    :loading="cargando"
    hide-details="auto"
  >
  </v-select>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import PuestoService from '@/api/servicios/PuestoService'
import { mdiGroup } from '@mdi/js'
import MaestroService from '@/api/servicios/MaestroService'
export default {
  props: {
    value: String,
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    todos: Boolean,
    SelectDefault: Number,
    icon: Boolean,
    rules: Object,
  },
  setup(props, contex) {
    const datos = ref([])
    const datoselect = ref(null)
    const cargando = ref(false)
    onBeforeMount(async () => {
      await cargarDatos()
    })

    onBeforeMount(() => {
      datoselect.value = props.value
      //dialog.value.save(date.value)
    })

    watch(props, () => {
      datoselect.value = props.value 
    })

    const cargarDatos = async () => {
      datos.value = []
      if (props.todos == true) {
        datos.value.push({ id: -1, descripcion: 'TODOS' })
      }
      cargando.value = true
      MaestroService.listarMonedas()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = datos.value.concat(response.data.datos)
          }
          if (props.SelectDefault != undefined) {
            datoselect.value = props.SelectDefault
            contex.emit(
              'input',
              datoselect.value
            )
          }
          console.log(response.data)
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargardatos',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }
    watch(datoselect, () => {
      contex.emit(
        'input',
        datoselect.value
      )
    })

    return {
      icons: {
        mdiGroup,
      },
      datos,
      datoselect,
      cargando,
    }
  },
}
</script>